import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import PageHeading from "../components/page-heading"
import FaqAccordion from "../components/faq-accordion"
import FooterImage from "../components/footer-image"

const FaqPage = () => (
  <Layout>
    <SEO
      title="Häufige Fragen (FAQ)"
      description="Alle Antworten zum BürgerBot damit Sie Ihre Anliegen per Smartphone versenden können."
    />
    <PageHeading heading="Häufige Fragen (FAQ)" />
    <section>
      <Container>
        <Row>
          <Col className="col-12 mb-4" data-aos="fade-up">
            <FaqAccordion firstActive={true}>
              {[
                {
                  question: "Was ist der BürgerBot?",
                  answer: (
                    <p>
                      Der BürgerBot ist ein Chatbot, der Anliegen und
                      Beschwerden von Bürgerinnen und Bürgern aufnehmen kann und
                      diese an die Stadtverwaltung weiterleitet. Sie können mit
                      ihm ähnlich wie mit einem richtigen Menschen chatten und
                      auch Fotos oder den betroffenen Standort übermitteln. Der
                      BürgerBot leitet dann Ihre Anfrage an die zuständige
                      Person in der Stadtverwaltung weiter.
                    </p>
                  ),
                },
                {
                  question: "Was ist ein Bot oder Chatbot?",
                  answer: (
                    <p>
                      Ein Chatbot, oder auch Bot genannt, ist ein technisches
                      System, das automatisch auf Nachrichten antworten kann.
                      Mit dem BürgerBot lässt es sich z. B. in ähnlicher Weise
                      wie mit einem Menschen chatten, nur dass die Antworten von
                      einer künstlichen Intelligenz erstellt werden. Dadurch
                      kann der BürgerBot rund um die Uhr erreichbar sein und
                      Ihre Anfragen entgegennehmen.
                    </p>
                  ),
                },
                {
                  question: "Wie erreiche ich den BürgerBot?",
                  answer: (
                    <>
                      <p>
                        Klicken Sie auf den Button "Kontaktdaten finden" und
                        sehen Sie nach, ob Ihre Stadt bereits den BürgerBot
                        verwendet. Dort werden Ihnen die Kontaktdaten für alle
                        aktuell unterstützten Messenger-Apps angezeigt. Öffnen
                        Sie nun eine beliebige Messenger-App und suchen Sie
                        darin die BürgerBot-Nummer Ihrer Stadt oder den
                        angezeigten Kontaktnamen. Alternativ klicken Sie direkt
                        auf den Button Ihrer bevorzugten Messenger-App, welche
                        sich dann automatisch öffnen wird.
                      </p>
                      <Link
                        to="/buerger#contact-bot-map"
                        className="custom-btn btn mt-3 mr-3"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        Kontaktdaten finden
                      </Link>
                    </>
                  ),
                },
                {
                  question: "Warum finde ich meine Stadt nicht?",
                  answer: (
                    <p>
                      Der BürgerBot kann prinzipiell von jeder Stadt verwendet
                      werden. Wenn Ihre Stadt noch nicht dabei ist, können Sie
                      Ihre Stadtverwaltung gerne über den BürgerBot informieren,
                      sodass diese mit uns in Kontakt treten kann.
                    </p>
                  ),
                },
                {
                  question: "Wie melde ich ein Anliegen?",
                  answer: (
                    <p>
                      Mit dem BürgerBot können Sie wie mit einer natürlichen
                      Person chatten. Sie teilen ihm Ihr Anliegen oder Ihre
                      Beschwerde wie beispielsweise einen überfüllten Mülleimer
                      in Ihrem Stadtteil, mit. Dabei führt der Chatbot Sie durch
                      das Gespräch und erfragt so die Informationen, welche die
                      Stadtverwaltung braucht. Um Ihr Anliegen genauer zu
                      beschreiben, können Sie auch ein Foto oder den Standort
                      der überfüllten Mülltonne übermitteln. Sobald der
                      BürgerBot genug Informationen hat, wird er Sie auffordern,
                      "Absenden" zu schreiben und das Gespräch an die
                      zuständigen Mitarbeiterinnen und Mitarbeiter der Stadt
                      weitergeben. Diese werden sich dann um die Bearbeitung
                      Ihres Anliegens kümmern.
                    </p>
                  ),
                },
                {
                  question: "Wie bekomme ich Hilfe bei der Bedienung?",
                  answer: (
                    <p>
                      Sollten Sie Hilfe bei der Bedienung benötigen, können Sie
                      dem BürgerBot auch einfach "Hilfe" schreiben. Dieser wird
                      Ihnen dann seine Funktionsweise erklären.
                    </p>
                  ),
                },
                {
                  question: "Wer sieht was ich schreibe?",
                  answer: (
                    <>
                      <p>
                        Sobald Sie Ihr Anliegen absenden, wird es gespeichert
                        und mithilfe von KI-Technologie der jeweils zuständigen
                        Person in der Stadtverwaltung angezeigt. Ihr Chatverlauf
                        mit dem BürgerBot wird dabei auf ISO
                        27001-zertifizierten Servern in Deutschland
                        abgespeichert.
                      </p>
                      <p>
                        Haben Sie Ihr Anliegen noch nicht durch das Schreiben
                        von "Absenden" an die Stadt weitergeleitet, können Sie
                        jederzeit "Neustart" schreiben und der BürgerBot
                        vergisst Ihre bisherigen Nachrichten.
                      </p>
                    </>
                  ),
                },
                {
                  question: "Wie kann ich abbrechen/neustarten?",
                  answer: (
                    <p>
                      Sie können jederzeit neu beginnen, indem Sie "Neustart"
                      schreiben. Dabei vergisst der BürgerBot alle noch nicht an
                      die Stadt weitergeleiteten Nachrichten.
                    </p>
                  ),
                },
                {
                  question: "Was passiert bei Rückfragen?",
                  answer: (
                    <p>
                      Sollte es eine Rückfrage seitens der Stadt zu Ihrem
                      Anliegen geben, kann Ihnen diese direkt in Ihre verwendete
                      Messenger-App zugestellt werden. Wenn Sie also z. B.
                      WhatsApp für die Kontaktaufnahme mit dem BürgerBot genutzt
                      haben, werden Sie die Rückfrage der Stadt auch über
                      WhatsApp erhalten. Sie können auf die Rückfrage dann
                      direkt antworten. Sobald sich alle Nachfragen geklärt
                      haben, erhalten Sie darüber eine weitere Benachrichtigung
                      und können dann den BürgerBot wieder wie gewohnt nutzen.
                    </p>
                  ),
                },
              ]}
            </FaqAccordion>
          </Col>
        </Row>
      </Container>
    </section>
    <FooterImage />
  </Layout>
)

export default FaqPage
