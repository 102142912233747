import React, { ReactElement, useState } from "react"
import { Accordion, Card } from "react-bootstrap"
import classNames from "classnames"

import "./faq-accordion.css"

/**
 * Interface to describe a question with answer.
 */
interface IFaq {
  question: string
  answer: ReactElement
}

interface IProps {
  firstActive?: boolean
  children: IFaq[]
}

/**
 * Accordion for displaying FAQ.
 * @param props props
 */
const FaqAccordion = (props: IProps) => {
  const [activeEventKey, setActiveEventKey] = useState(
    props.firstActive ? 0 : undefined
  )

  return (
    <Accordion activeKey={String(activeEventKey)}>
      {props.children.map((faq, i) => (
        <Card
          key={i}
          className={classNames("faq-card", { active: activeEventKey === i })}
        >
          <Accordion.Toggle
            as={Card.Header}
            eventKey={String(i)}
            onClick={() =>
              setActiveEventKey(activeEventKey !== i ? i : undefined)
            }
          >
            <span className="faq-question mr-2">{faq.question}</span>
            <span className="faq-icon">
              <i className="faq-icon fa fa-chevron-right"></i>
            </span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={String(i)}>
            <Card.Body>{faq.answer}</Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  )
}

export default FaqAccordion
